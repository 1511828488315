import { APP_VERSION } from '@manager-env/version';

export const environment = {
  production: true,
  version: APP_VERSION,
  sentryUrl: 'https://579517b18e1d46b386b3836ba83ce500@sentry.happycake.co/4',
  keycloakUrl: 'https://auth.happyapps.pro/auth',
  keycloakRealm: 'happy',
  keycloakClientId: 'manager-frontend',
  googleMapsApiKey: 'AIzaSyDPz9ZKroutn4O7Erujg1_TKGXR3hqsy08'
};
